<template>
  <div class="w-full h-full pt-5">
    <div v-if="hasModuleAccess">
      <div class="flex">
        <h1 class="text-xl text-left font-extrabold pl-4 mr-8">
          Performance Agreements
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
      <div style="height: 100%" class="mt-20" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div
        v-if="!loading && payload.appraisalCycleId !== ''"
        class="flex w-full p-5"
      >
        <template>
          <div class="w-full flex flex-col gap-5">
            <div class="w-full">
              <Card class="p-5 pt-0">
                <div
                  class="flex p-2 mt-4 rounded-md bg-yellow-50 border border-yellow-500"
                  style="width: fit-content; float: right"
                  v-if="endAgreement"
                >
                  <Icon
                    class-name="text-carrotOrange mx-auto self-center"
                    size="xs"
                    icon-name="icon-info"
                  />
                  <span class="flex text-sm mx-3">
                    Agreement contracting period ended on
                    {{
                      $DATEFORMAT(new Date(agreementCloseDate), "MMMM dd, yyyy")
                    }}
                  </span>
                </div>

                <div
                  class="flex mx-4 p-2 mb-2 rounded-md bg-yellow-50 border border-yellow-500"
                  v-if="!endAgreement && !startAgreement"
                  style="width: fit-content"
                >
                  <span
                    class="flex text-sm mx-3"
                    v-if="!endAgreement && !startAgreement"
                  >
                    Agreement contracting period starts on
                    {{
                      $DATEFORMAT(new Date(agreementOpenDate), "MMMM dd, yyyy")
                    }}
                    and ends on
                    {{
                      $DATEFORMAT(new Date(agreementCloseDate), "MMMM dd, yyyy")
                    }}
                  </span>
                </div>
                <CardFooter
                  reloadcard
                  @searchResult="handleSearch($event)"
                  showcalendar
                  @reload="reload()"
                />
              </Card>
            </div>
            <template>
              <div class="w-full" v-if="viewRegister">
                <Table
                  :headers="headers"
                  :items="items"
                  aria-label="advance Table"
                  class="w-full px-0"
                  :has-checkbox="false"
                  :has-number="false"
                  :loading="loadingTable"
                  :pagination-list="metaData"
                  @page="handlePage($event)"
                  @itemsPerPage="handleItemsPerPage($event)"
                  page-sync
                  v-if="items.length > 0 || loadingTable"
                >
                  <template v-slot:item="{ item }">
                    <div
                      v-if="item.year"
                      class="font-semibold text-sm leading-5 tracking-widest"
                    >
                      <span class="text-darkPurple">
                        {{ item.data.year }}
                      </span>
                    </div>
                    <div v-else-if="item.periodStart" class="flex flex-wrap">
                      <span class="flex text-sm">
                        {{ item.data.periodStart }}
                      </span>
                    </div>
                    <div
                      v-else-if="item.employees"
                      class="font-semibold text-sm leading-5 tracking-widest whitespace-nowrap"
                    >
                      <span class="text-darkPurple">
                        {{ item.data.employees }} Employees</span
                      >
                      <div class="text-xs text-romanSilver">
                        <span class="text-romanSilver">
                          {{ item.data.submitted }} Submitted</span
                        >
                        <span
                          :class="{
                            'text-flame':
                              (item.data.submitted / item.data.employees) *
                                100 <
                              100,
                            'text-mediumSeaGreen':
                              (item.data.submitted / item.data.employees) *
                                100 ===
                              100,
                          }"
                        >
                          ({{
                            (item.data.submitted / item.data.employees) * 100
                              ? Math.round(
                                  (item.data.submitted / item.data.employees) *
                                    100
                                )
                              : "0"
                          }}%)
                        </span>
                      </div>
                    </div>
                    <div v-else-if="item.status">
                      <Badge
                        :label="item.data.status"
                        variant="primary"
                        :background-color="
                          item.data.status === 'active'
                            ? `rgba(19, 181, 106, 0.15)`
                            : `rgba(135, 142, 153, 0.15)`
                        "
                        :color="
                          item.data.status === 'active' ? '#13B56A' : '#333333'
                        "
                        class="capitalize"
                        style="
                          margin-right: 12px;
                          font-size: 14px;
                          padding: 12px 10px;
                          height: 35px;
                          border-radius: 5px;
                        "
                      />
                    </div>
                    <div v-else-if="item.id">
                      <Menu left top="-130" class="p-0" margin="26">
                        <template v-slot:title>
                          <icon icon-name="more_icon" size="xs" />
                        </template>
                        <div style="width: 170px; min-height: 11px" class="p-1">
                          <div
                            class="flex pt-1 px-2 more h-8 cursor-pointer"
                            @click="
                              $router.push({
                                name: 'PerformanceAgreementDetails',
                                params: {
                                  id: item.data.id,
                                  year: item.data.year,
                                },
                              })
                            "
                          >
                            <icon
                              icon-name="icon-eye"
                              size="xsm"
                              class="-mt-1 -ml-2 text-blueCrayola"
                              style="
                                padding: 3px 10px;
                                width: 36px;
                                height: 36px;
                              "
                            />
                            <p class="pt-1" style="font-size: 14px">
                              View Agreement
                            </p>
                          </div>

                          <div
                            class="flex py-3 px-3 mb-1 more h-8 cursor-pointer"
                            @click="updateWindow()"
                            v-if="endAgreement && item.data.status === 'active'"
                          >
                            <icon
                              icon-name="arrow-up"
                              size="xsm"
                              class="-mt-1 -ml-2"
                              style="
                                padding: 3px 10px;
                                width: 33px;
                                height: 33px;
                              "
                            />
                            <p class="pt-1" style="font-size: 14px">
                              Update Window
                            </p>
                          </div>

                          <div
                            class="flex py-3 px-3 more h-8 cursor-pointer"
                            @click="extendWindow()"
                            v-if="endAgreement && item.data.status === 'active'"
                          >
                            <icon
                              icon-name="maximize"
                              size="xsm"
                              class="-mt-1 -ml-2"
                              style="
                                padding: 3px 10px;
                                width: 33px;
                                height: 33px;
                              "
                            />
                            <p class="pt-1" style="font-size: 14px">
                              Extensions
                            </p>
                          </div>
                        </div>
                      </Menu>
                    </div>
                  </template>
                </Table>

                <div class="w-full" v-else>
                  <div
                    class="w-full flex flex-col justify-center items-center mt-20"
                  >
                    <icon
                      icon-name="empty_agreement"
                      size="l"
                      style="width: 300px"
                    />
                    <div class="w-1/2 text-base text-center">
                      No performance agreements displayed. Added agreements will
                      be displayed once they are added.
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <ErrorComponent />
              </div>
            </template>
          </div>
        </template>
      </div>

      <div class="w-full" v-if="!loading && payload.appraisalCycleId === ''">
        <div class="w-full flex flex-col justify-center items-center mt-20">
          <icon icon-name="empty_agreement" size="l" style="width: 300px" />
          <div class="w-1/2 text-base text-center">
            No performance agreements displayed. Added agreements will be
            displayed once they are added.
          </div>
        </div>
      </div>

      <RightSideBar
        v-if="windowModal"
        @close="closeWindow"
        submit="Send"
        @submit="windowExtension"
        :button-class="`bg-dynamicBackBtn text-white`"
      >
        <template v-slot:title>
          <div class="w-full flex">
            <p class="text-darkPurple mt-3">Contract Review</p>
          </div>
        </template>

        <template v-slot:subtitle>
          <div class="flex flex-col w-full mb-3">
            <p class="my-2 text-base">
              This action enables you release performance contracts back to
              employees to make changes. Select your update window to complete.
            </p>
          </div>
        </template>

        <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
          <div class="col-span-12">
            <div class="text-sm text-jet">Start Date</div>
            <div
              class="date-flex mt-1"
              :style="startDateError ? 'border : 1px solid red' : ''"
            >
              <datepicker
                placeholder="--Select Day & Month & Year--"
                style="width: 100%; outline: none"
                input-class="date-input"
                @selected="startDateSelect"
                v-model="payload.startDate"
              />
            </div>
            <small
              data-v-d5a084b0=""
              class="mt-1 text-red-700 text-xs font-semibold"
              v-if="startDateError"
              >Start Date is not valid.</small
            >
          </div>

          <div class="col-span-12">
            <div class="text-sm text-jet">End Date</div>
            <div
              class="date-flex mt-1"
              :style="endDateError ? 'border : 1px solid red' : ''"
            >
              <datepicker
                placeholder="--Select Day & Month & Year--"
                style="width: 100%; outline: none"
                input-class="date-input"
                @selected="endDateSelect"
                v-model="payload.endDate"
              />
            </div>
            <small
              data-v-d5a084b0=""
              class="mt-1 text-red-700 text-xs font-semibold"
              v-if="endDateError"
              >End Date is not valid.</small
            >
          </div>

          <div class="col-span-12">
            <div class="text-sm text-jet">Justification</div>
            <CTextarea
              placeholder="--Message--"
              variant="primary"
              class="mt-2"
              :style="commentError ? 'border : 1px solid red' : ''"
              value=""
              :row="8"
              :height="90"
              :col="30"
              @input="commentView($event)"
              v-model="payload.comment"
            />
            <small
              data-v-d5a084b0=""
              class="mt-1 text-red-700 text-xs font-semibold"
              v-if="commentError"
              >Justification Field is not valid.</small
            >
          </div>
        </div>
      </RightSideBar>

      <RightSideBar
        v-if="agreementModal"
        @close="closeWindow"
        submit="Send"
        @submit="agreementExtension"
        :button-class="`bg-dynamicBackBtn text-white`"
      >
        <template v-slot:title>
          <div class="w-full flex">
            <p class="text-darkPurple mt-3">Agreement Extension</p>
          </div>
        </template>

        <template v-slot:subtitle>
          <div class="flex flex-col w-full mb-3">
            <p class="my-2 text-base">
              This action enables you extend the contract submission deadline
              for all employees. Select a new submission deadline to complete
            </p>
          </div>
        </template>

        <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
          <div class="col-span-12">
            <div class="text-sm text-jet">Extend To</div>
            <div
              class="date-flex mt-1"
              :style="endDateError ? 'border : 1px solid red' : ''"
            >
              <datepicker
                placeholder="--Select Day & Month & Year--"
                style="width: 100%; outline: none"
                input-class="date-input"
                @selected="endDateSelect"
                v-model="agreementPayload.endDate"
              />
            </div>
            <small
              data-v-d5a084b0=""
              class="mt-1 text-red-700 text-xs font-semibold"
              v-if="endDateError"
              >Select Date.</small
            >
          </div>

          <div class="col-span-12">
            <div class="text-sm text-jet">Comments</div>
            <CTextarea
              placeholder="--Comment--"
              variant="primary"
              class="mt-2"
              :style="commentError ? 'border : 1px solid red' : ''"
              value=""
              :row="8"
              :height="90"
              :col="30"
              @input="commentView($event)"
              v-model="agreementPayload.comment"
            />
            <small
              data-v-d5a084b0=""
              class="mt-1 text-red-700 text-xs font-semibold"
              v-if="commentError"
              >Comment Field is not valid.</small
            >
          </div>
        </div>
      </RightSideBar>
    </div>
    <div v-else>
      <ErrorComponent />
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { ValidationObserver } from "vee-validate";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Datepicker from "vuejs-datepicker";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Button from "@/components/Button";
import CardFooter from "@/components/CardFooter";
import Loader from "@/components/Loader";
import Icon from "@/components/Icon";
import RightSideBar from "@/components/RightSideBar";
import Menu from "@/components/Menu";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  name: "Function",
  mixins: [paramsMixin, timeFormatMixin],
  // eslint-disable-next-line vue/no-unused-components
  components: {
    ValidationObserver,
    Datepicker,
    ErrorComponent,
    Menu,
    CTextarea,
    Breadcrumb,
    Badge,
    Button,
    Card,
    Table,
    CardFooter,
    Loader,
    Icon,
    RightSideBar,
  },
  data() {
    return {
      viewRegister: false,
      hasModuleAccess: false,
      perfArray: [],
      openModal: false,
      kpaSummary: {},
      loading: true,
      appraisalsArr: [],
      windowModal: false,
      agreementModal: false,
      startDateError: false,
      endDateError: false,
      commentError: false,
      loadingTable: true,
      agreementOpenDate: "",
      agreementCloseDate: "",
      startAgreement: false,
      endAgreement: false,
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Agreements",
          href: "Performance Agreements",
          id: "Agreement",
        },
      ],
      headers: [
        { title: "Financial Year", value: "year" },
        { title: "Description", value: "description" },
        { title: "Period", value: "periodStart" },
      ],
      items: [],
      shortTermGoals: [],
      paginationList: { page: 1, lastPage: 1, total: 1, from: 1, to: 1 },
      payload: {
        orgId: "",
        appraisalCycleId: "",
        year: "",
        operation: "",
        comment: "",
        endDate: "",
        startDate: "",
        attachment: "",
      },
      agreementPayload: {
        orgId: "",
        appraisalCycleId: "",
        year: "",
        operation: "",
        comment: "",
        endDate: "",
      },
      searchQuery: "",
    };
  },

  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getAppraisalCycle(
        `${this.agreementQuery()}${pageNumber}${itemPage}${search}`
      );
    },

    handleSearch(value) {
      this.searchQuery = `&search=${value}`;
      this.loadingTable = true;
      this.getAppraisalCycle(`${this.agreementQuery()}${this.searchQuery}`);
    },

    reload() {
      this.loadingTable = true;
      this.getAppraisalCycle(this.agreementQuery());
    },

    async updateWindow() {
      try {
        await this.$handlePrivilege(
          "performanceAgreements",
          "updatePerformanceContractsWindow"
        );
        this.windowModal = true
      }
      catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async extendWindow() {
      try {
        await this.$handlePrivilege(
          "performanceAgreements",
          "extendPerformanceContractsDeadline"
        );
        this.agreementModal = true
      }
      catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    commentView(value) {
      if (value !== "") {
        this.commentError = false;
      }
    },

    startDateSelect() {
      this.startDateError = false;
    },

    endDateSelect() {
      this.endDateError = false;
    },

    createKpaSummary(kpis, name) {
      this.kpaSummary = { kpis, name };
      this.openModal = true;
    },

    getAgreements() {
      const agreement = [];
      this.items = [];

      this.$_getOrgPerformanceAgreement()
        .then((response) => {
          const responseData = response.data.data;

          responseData.map((data) => {
            agreement.push({
              compliance: data.compliance,
              employees: data.totalEmployees,
              submitted: data.SubmittedAgreement,
            });

            agreement.push(
              this.appraisalsArr.reduce((r, i) => Object.assign(r, i), {})
            );

            agreement.push(
              this.shortTermGoals.reduce((r, i) => Object.assign(r, i), {})
            );

            this.items.push(
              agreement.reduce((r, i) => Object.assign(r, i), {})
            );

            return {};
          });

          this.loading = false;
          this.loadingTable = false;
        })
        .catch(() => {
          this.loading = false;
          this.loadingTable = false;
        });
    },

    async getOrganizationGoal() {
      try {
        await this.$handlePrivilege(
          "performanceAgreements",
          "viewPerformanceContractRegister"
        );
        this.viewRegister = true;
        this.shortTermGoals = [];
        this.$_getOrganizationGoal(this.searchQuery).then((response) => {
          const goalData = response.data.goals;

          if (goalData.length > 0) {
            goalData.forEach((goals) => {
              this.shortTermGoals.push({
                id: goals.goal.id,
                year: goals.goal.year,
                description:
                  goals.goal.goal_performance_templates?.agreement_description,
                status: goals.goal.status,
              });

              return {};
            });

            this.getAgreements();
          } else {
            this.loading = false;
            this.loadingTable = false;
          }
        });
      } catch (error) {
        this.viewRegister = false;
      }
    },

    getAppraisalCycle(params) {
      this.$_getAppraisalCycle(params).then((response) => {
        const appraisal = response.data.AppraisalCycle;
        this.metaData = response.data.meta;

        if (appraisal.length > 0) {
          const agreementStartDate = this.setDates(
            appraisal[0].agreement_start
          );
          const agreementEndDate = this.setDates(appraisal[0].agreement_end);

          agreementEndDate.setDate(agreementEndDate.getDate() + 1);

          this.agreementOpenDate = agreementStartDate.toISOString();
          this.agreementCloseDate = this.setDates(
            appraisal[0].agreement_end
          ).toISOString();

          const today = new Date();

          if (today >= agreementStartDate && today <= agreementEndDate) {
            this.startAgreement = true;
            this.endAgreement = false;
          }

          if (today > agreementStartDate && today > agreementEndDate) {
            this.startAgreement = false;
            this.endAgreement = true;
          }

          if (today < agreementStartDate && today < agreementEndDate) {
            this.startAgreement = false;
            this.endAgreement = false;
          }

          const apprArray = [];

          appraisal.map((appraisalCycles) => {
            this.payload.appraisalCycleId = appraisalCycles.id;
            this.agreementPayload.appraisalCycleId = appraisalCycles.id;

            const startDate = `${appraisalCycles.cycle_start_date}`;

            const endDate = `${appraisalCycles.cycle_end_date}`;

            const hasProperty = this.headers.some(
              (header) => header.title === "Status"
            );

            apprArray.push({
              periodStart: `${this.formatDateTime(
                startDate
              )} - ${this.formatDateTime(endDate)}`,
            });

            appraisalCycles.cycles.map((cycle) => {
              if (!hasProperty) {
                this.headers.push({
                  title: cycle.name,
                  value: cycle.name,
                });

                apprArray.push({
                  [cycle.name]: `${this.formatDateTime(
                    cycle.appraisal_starts
                  )} - ${this.formatDateTime(cycle.appraisal_ends)}`,
                });
              }
              return {};
            });

            if (!hasProperty) {
              this.appraisalsArr.push(
                apprArray.reduce((r, i) => Object.assign(r, i), {})
              );

              this.headers.push(
                { title: "Employees", value: "employees" },
                { title: "Status", value: "status" },
                { title: "", value: "id", image: true }
              );
            }
            return {};
          });

          this.getOrganizationGoal();
        } else {
          this.loading = false;
        }
      });
    },

    payloadDate(value) {
      if (value) {
        const month = this.$DATEFORMAT(new Date(value), "MM");
        const day = this.$DATEFORMAT(new Date(value), "dd");
        return `${day}/${month}`;
      }
      return "";
    },

    closeWindow() {
      this.startDateError = false;
      this.endDateError = false;
      this.commentError = false;
      this.windowModal = false;
      this.agreementModal = false;

      this.agreementPayload.year = "";
      this.agreementPayload.operation = "";
      this.agreementPayload.comment = "";
      this.agreementPayload.endDate = "";

      this.payload.year = "";
      this.payload.operation = "";
      this.payload.comment = "";
      this.payload.endDate = "";
      this.payload.startDate = "";
      this.payload.attachment = "";
    },

    windowExtension() {
      this.payload.orgId = this.$orgId;
      const currentYear = new Date().getFullYear();
      this.payload.year = currentYear;
      this.payload.operation = "contract";

      if (this.payload.startDate === "") {
        this.startDateError = true;
      }
      if (this.payload.endDate === "") {
        this.endDateError = true;
      }
      if (this.payload.comment === "") {
        this.commentError = true;
      } else if (
        this.payload.startDate !== "" &&
        this.payload.endDate !== "" &&
        this.payload.comment !== "" &&
        !this.payload.id
      ) {
        this.payload.endDate = `${this.payload.endDate.getFullYear()}/${
          this.payload.endDate.getMonth() + 1
        }/${this.payload.endDate.getDate()}`;
        this.payload.startDate = `${this.payload.startDate.getFullYear()}/${
          this.payload.startDate.getMonth() + 1
        }/${this.payload.startDate.getDate()}`;

        this.$_extendContractWindow(this.payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.closeWindow();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      }

      if (this.payload.id) {
        this.payload.endDate = `${this.payload.endDate.getFullYear()}/${
          this.payload.endDate.getMonth() + 1
        }/${this.payload.endDate.getDate()}`;
        this.payload.startDate = `${this.payload.startDate.getFullYear()}/${
          this.payload.startDate.getMonth() + 1
        } /${this.payload.startDate.getDate()}`;

        this.$_updateContractWindow(this.payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.closeWindow();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      }
    },

    agreementExtension() {
      this.agreementPayload.orgId = this.$orgId;
      const currentYear = new Date().getFullYear();
      this.agreementPayload.year = currentYear;
      this.agreementPayload.operation = "agreement";

      if (this.agreementPayload.endDate === "") {
        this.endDateError = true;
      }
      if (this.agreementPayload.comment === "") {
        this.commentError = true;
      } else if (
        this.agreementPayload.endDate !== "" &&
        this.agreementPayload.comment !== "" &&
        !this.agreementPayload.id
      ) {
        this.agreementPayload.endDate = `${this.agreementPayload.endDate.getFullYear()}/${
          this.agreementPayload.endDate.getMonth() + 1
        }/${this.agreementPayload.endDate.getDate()}`;

        this.agreementPayload.extendTo = this.agreementPayload.endDate;
        this.$_extendContractWindow(this.agreementPayload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.closeWindow();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      }

      if (this.agreementPayload.id) {
        this.agreementPayload.extendTo = this.agreementPayload.endDate;
        this.agreementPayload.cycleId = this.agreementPayload.appraisalCycleId;
        this.$_updateContractWindow(this.agreementPayload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.closeWindow();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      }
    },

    contractExtensionsData() {
      const currentYear = new Date().getFullYear();
      this.$_getExtensionDocumentation(`contract`, currentYear).then(
        (response) => {
          const responseData = response.data.data;

          responseData.map((data) => {
            this.payload.appraisalCycleId = data.appraisalCycleId;
            this.payload.year = data.year;
            this.payload.comment = data.comment;
            this.payload.operation = data.operation;
            this.payload.startDate = new Date(data.startDate);
            this.payload.endDate = new Date(data.endDate);
            this.payload.id = data.id;

            return {};
          });
        }
      );
    },

    agreementExtensionsData() {
      const currentYear = new Date().getFullYear();
      this.$_getExtensionDocumentation(`agreement`, currentYear).then(
        (response) => {
          const responseData = response.data.data;

          responseData.map((data) => {
            this.agreementPayload.appraisalCycleId = data.appraisalCycleId;
            this.agreementPayload.year = data.year;
            this.agreementPayload.comment = data.comment;
            this.agreementPayload.operation = data.operation;
            this.agreementPayload.endDate = new Date(data.endDate);
            this.agreementPayload.id = data.id;

            return {};
          });
        }
      );
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "performanceAgreements",
        "viewPerformanceContractRegister"
      );
      this.hasModuleAccess = true;
      this.getAppraisalCycle("");
      this.contractExtensionsData();
      this.agreementExtensionsData();
    } catch (error) {
      this.hasModuleAccess = false;
    }
  },
};
</script>

<style>
.rightSidebar {
  overflow-x: auto;
}
</style>
